<template>
  <div class="home">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'Home' }">{{
        app_name
      }}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'TaskList' }">{{
        $t("buttons.task_center")
      }}</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-block">
      <div class="page-block-header">
        <div class="content-title">
          <p>{{ $t("buttons.task_center") }}</p>
        </div>
        <div class="searchbars" v-if="user_name === admin_name">
          <div>
            <div style="display: flex">
              <el-select
                v-model="user_id_show"
                :placeholder="$t('buttons.select_user')"
                :disabled="users_list.length === 0"
                filterable
                clearable
              >
                <el-option
                  :label="$t('taskcenter.search_placeholder')"
                  :value="''"
                ></el-option>
                <el-option
                  v-for="item in users_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!-- <div>
            <el-input
              v-model="serach_keywords"
              placeholder="Search VOD by name in current results"
            >
              <el-button
                type="primary"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </div> -->
        </div>
      </div>

      <div class="page-block-content">
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="handleCreateTask"
            >{{ $t("taskcenter.create_task") }}</el-button
          >
        </div>
        <div class="results-part">
          {{ $t("taskcenter.task_all") }}: {{ n_tasks }}
          <el-button
            type="text"
            icon="el-icon-refresh"
            @click="refreshTaskList"
          ></el-button>
        </div>
      </div>
    </div>
    <div class="page-content-fill" v-loading="content_in_processing">
      <el-table :data="task_list" stripe style="width: 100%">
        <el-table-column prop="id" fixed="left" label="ID" width="80">
        </el-table-column>
        <el-table-column :label="$t('taskcenter.taskname')" width="80">
          <template slot-scope="scope">
            <span v-if="user_name === admin_name"
              >{{ user_id2name(scope.row.owner_id) }}
            </span>
            <span v-else>{{ user_name }} </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('taskcenter.created')" width="200">
          <template slot-scope="scope">
            {{ scope.row.created_at | toLocalTime | formatLocalTime }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('taskcenter.comments')" width="300">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.note"
              placement="top"
            >
              <p
                style="
                  cursor: pointer;
                  margin: 0;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 280px;
                  white-space: nowrap;
                "
              >
                {{ scope.row.note }}
              </p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column :label="$t('taskcenter.status')">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status == 'finished'">
              {{ $t("taskcenter.status_finished") }}
            </el-tag>
            <el-tag type="info" v-if="scope.row.status == 'running'">
              {{ $t("taskcenter.status_running") }}
            </el-tag>
            <el-tag type="warning" v-if="scope.row.status == 'submitted'">
              {{ $t("taskcenter.status_committed") }}
            </el-tag>
            <el-tag type="warning" v-if="scope.row.status == 'waiting'">
              {{ $t("taskcenter.status_waiting") }}
            </el-tag>
            <el-tag type="danger" v-if="scope.row.status == 'failed'">
              {{ $t("taskcenter.status_failed") }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('buttons.operation')"
          fixed="right"
          width="250"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleZoomInTask(scope.row.id)"
              >{{ $t("taskcenter.task_info") }}</el-button
            >
            <el-popconfirm
              :title="
                $t('taskcenter.del_task_prefix') +
                scope.row.id +
                $t('taskcenter.del_task_suffix')
              "
              :confirmButtonText="$t('buttons.confirm')"
              :cancelButtonText="$t('buttons.cancel')"
              @onConfirm="handleDeleteTask(scope.row.id)"
            >
              <el-button
                slot="reference"
                size="mini"
                type="danger"
                style="margin-left: 10px"
                :disabled="scope.row.status === 'running'"
                >{{ $t("buttons.delete") }}</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="prev, pager, next"
        :total="n_tasks"
        :page-size="n_tasks_per_page"
        :current-page.sync="n_page"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import apiURL from "@/data/api";

import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "TaskCenter",
  components: {},
  props: {
    user_id_show: {
      default: "",
    },
  },
  data() {
    return {
      task_list: [],
      n_tasks: 0,
      n_tasks_per_page: 10,
      n_page: 1,
      // user_id_show: ""
    };
  },
  watch: {
    user_id_show(nv) {
      this.refreshTaskList();
      console.log(nv);
    },
  },
  beforeMount() {
    this.switchDefaultPage("/dashboard/task-center");
  },
  computed: {
    ...mapGetters(["headers4reqs", "user_dict"]),
    ...mapState({
      user_name: (state) => state.user.statistics.name,
      user_statistics: (state) => state.user.statistics,
      admin_name: (state) => state.admin_name,
      users_list: (state) => state.users,
      app_name: (state) => state.app_name,
    }),
  },
  mounted() {
    this.refreshTaskList();
  },
  methods: {
    ...mapActions(["switchDefaultPage"]),
    user_id2name(i) {
      if (i in this.user_dict) {
        return this.user_dict[i];
      } else {
        return this.$t("reminders.loading");
      }
    },
    async getTaskList() {
      var params = {
        skip: (this.n_page - 1) * this.n_tasks_per_page,
        limit: this.n_tasks_per_page,
      };
      if (this.user_id_show !== "") {
        params.user_id = this.user_id_show;
      }
      await this.$get_api(
        apiURL.task.list,
        null,
        this.headers4reqs,
        params
      ).then((res) => {
        this.n_tasks = res.n_tasks;
        this.task_list = res.tasks;
      });
    },
    async handlePageChange(page_id) {
      console.log(page_id);
      await this.getTaskList();
    },
    async refreshTaskList() {
      this.n_page = 1;
      this.content_in_processing = true;
      await this.getTaskList();
      this.content_in_processing = false;
    },
    handleDeleteTask(task_id) {
      if (task_id !== null) {
        this.$delete_api(
          apiURL.task.details.replace("task_id", task_id),
          null,
          this.headers4reqs
        ).then((res) => {
          this.refreshTaskList();
          return res;
        });
      }

      return task_id;
    },
    handleCreateTask() {
      this.$router.push({ name: "TaskCreate" });
    },
    handleZoomInTask(id) {
      this.$router.push({ name: "TaskViewer", params: { id: id } });
    },
  },
  //
};
</script>
<style lang="scss" scoped>
@import "@/style/main";
</style>
